import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  Inject,
  Input,
  OnInit,
  Renderer2,
  forwardRef
} from '@angular/core';
import { MediaObserver } from '@angular/flex-layout';

import {
  Alignment,
  Background,
  COLORS,
  ColorMap,
  GRID,
  PaddingType,
  Page451Component,
  Theme,
  mixinAlign,
  mixinBackground,
  mixinPadding,
  mixinResponsive,
  mixinTheme
} from '../core';

import {
  PAGE451_IMAGE_TRANSFORMATION,
  PAGE451_TRANSFORMATION_SIZES,
  PAGE_COMPONENT,
  PAGE_CONFIG,
  Page451EditableGroupService
} from '../editor';

import { textConfigFactory } from './text.config';
import { IPgText, IPgTextElements } from './text.interface';

export class TextBase {
  constructor(
    public renderer: Renderer2,
    public elementRef: ElementRef,
    public media: MediaObserver,
    public cd: ChangeDetectorRef
  ) {}
}
export const _TextBase = mixinResponsive(
  mixinBackground(mixinPadding(mixinAlign(mixinTheme(TextBase, 'light'))))
);

@Component({
  selector: 'elm-pg-text',
  templateUrl: './text.component.html',
  styleUrls: ['./text.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    { provide: PAGE_CONFIG, useFactory: textConfigFactory },
    {
      provide: PAGE_COMPONENT,
      useExisting: forwardRef(() => TextComponent)
    },
    {
      provide: PAGE451_IMAGE_TRANSFORMATION,
      useValue: PAGE451_TRANSFORMATION_SIZES.MD
    },
    Page451EditableGroupService
  ]
})
export class TextComponent
  extends _TextBase
  implements Page451Component, IPgText, OnInit
{
  @Input() pageGuid: string;

  @Input() title: string;
  @Input() subtitle?: string;
  @Input() text: string;
  @Input() separatorColor: string;
  @Input() link: string;
  @Input() ariaLabel: string;

  @Input()
  elements: IPgTextElements = {
    title: true,
    subtitle: true,
    text: true,
    divider: true
  };

  @Input() aligned: Alignment;
  @Input() background: Background;
  @Input() padding: PaddingType;
  @Input() theme: Theme;

  constructor(
    elementRef: ElementRef,
    renderer: Renderer2,
    media: MediaObserver,
    cd: ChangeDetectorRef,
    @Inject(GRID) public _GRID,
    @Inject(COLORS) private _colors: ColorMap
  ) {
    super(renderer, elementRef, media, cd);
  }

  ngOnInit() {
    super.ngOnInit();

    if (!this.separatorColor) {
      this.separatorColor = this._colors._secondary;
    }
  }
}
