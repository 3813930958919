<elm-upload-thumbnail matTooltipPosition="above" [matTooltip]="error ? error.message : null" [file]="file" />

<div *ngIf="progress" class="progress">
  <div class="progress-bar"><ng-content select="elm-upload-progress"></ng-content></div>
</div>

<div *ngIf="error" class="error">
  <span class="error-indicator">
    <svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="32px" height="32px" viewBox="0 0 24 24">
      <g>
        <path
          d="M12,0C5.383,0,0,5.382,0,12c0,6.616,5.383,12,12,12s12-5.384,12-12C24,5.382,18.617,0,12,0z M12.321,20.487
		c-2.128,0-3.546-0.702-5.089-1.753L18.735,7.231c0.68,1,1.752,2.535,1.752,5.089C20.487,16.823,16.824,20.487,12.321,20.487z
		 M11.667,3.5c2.124,0,3.545,0.699,5.089,1.752L5.252,16.755c-0.682-1-1.752-2.536-1.752-5.089C3.5,7.163,7.164,3.5,11.667,3.5z"
        />
      </g>
    </svg>
  </span>
</div>
