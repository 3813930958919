<div class="files">
  <div class="file" *ngFor="let file of files; trackBy: trackByFile">
    <elm-upload-file [file]="file">
      <elm-upload-progress [progress]="file.progress" *ngIf="file.state === STATES.PROGRESS"></elm-upload-progress>
      <elm-upload-file-error
        message="There was an error uploading file"
        *ngIf="file.state === STATES.FAILED"
      ></elm-upload-file-error>
      <elm-upload-file-error
        message="File upload was cancelled"
        *ngIf="file.state === STATES.CANCELLED"
      ></elm-upload-file-error>
    </elm-upload-file>

    <ng-container [ngSwitch]="file.state">
      <span
        class="action elm-upload-files-file-action elm-caption elm-fg-warn elm-spacing-xs"
        *ngSwitchCase="STATES.INVALID"
      >
        Invalid
      </span>

      <ng-container *ngSwitchCase="STATES.FINISHED">
        <a class="action elm-upload-files-file-action elm-caption elm-fg-5 elm-spacing-xs" (click)="onRemove(file)">
          Remove
        </a>
        <ng-container *ngIf="file.file.url">
          <span class="action-separator elm-upload-files-file-action elm-caption elm-fg-5">|</span>
          <a class="view-action elm-caption elm-fg-5 elm-spacing-xs" (click)="onView(file)"> View </a>
        </ng-container>
      </ng-container>

      <a
        class="action elm-upload-files-file-action elm-caption elm-fg-5 elm-spacing-xs"
        *ngSwitchCase="STATES.PROGRESS"
        (click)="onCancel(file)"
      >
        Cancel
      </a>

      <ng-container *ngSwitchCase="STATES.CANCELLED">
        <a class="action elm-upload-files-file-action elm-caption elm-fg-5 elm-spacing-xs" (click)="onRetry(file)">
          Retry
        </a>
        <span class="action-separator elm-caption elm-fg-5">|</span>
        <a class="action elm-upload-files-file-action elm-caption elm-fg-5 elm-spacing-xs" (click)="onRemove(file)">
          Remove
        </a>
      </ng-container>

      <ng-container *ngSwitchCase="STATES.FAILED">
        <a class="action elm-upload-files-file-action elm-caption elm-fg-5 elm-spacing-xs" (click)="onRetry(file)">
          Retry
        </a>
        <span class="action-separator elm-caption elm-fg-5">|</span>
        <a class="action elm-upload-files-file-action elm-caption elm-fg-5 elm-spacing-xs" (click)="onRemove(file)">
          Remove
        </a>
      </ng-container>
    </ng-container>
  </div>
</div>
