import { InjectionToken } from '@angular/core';
import { FilesApi } from '@element451-libs/models451';
import { FileKind, FileType } from './models';

export const TOKEN_DEFAULT_ICON = new InjectionToken<string>(
  '[FILES] DEFAULT ICON'
);
export const TOKEN_DEFAULT_COLOR = new InjectionToken<string>(
  '[FILES] DEFAULT COLOR'
);
export const TOKEN_IMAGE_MIME_TYPES = new InjectionToken<string[]>(
  '[FILES] IMAGE MIME TYPES'
);
export const TOKEN_FILE_TYPES = new InjectionToken<FileType[]>(
  '[FILES] FILE TYPES'
);

export const DEFAULT_ICON = 'file-new-1';
export const DEFAULT_COLOR = '#C2C9CC';
export const IMAGE_MIME_TYPES = [
  FilesApi.MimeType.GIF,
  FilesApi.MimeType.JPEG,
  FilesApi.MimeType.PNG,
  FilesApi.MimeType.SVG_XML
];

export const FILE_TYPES: FileType[] = [
  {
    label: 'Image',
    icon: 'file-picture',
    mimeTypes: [
      FilesApi.MimeType.GIF,
      FilesApi.MimeType.JPEG,
      FilesApi.MimeType.PNG
    ],
    color: DEFAULT_COLOR,
    kind: FileKind.Image
  },
  {
    label: 'SVG',
    icon: 'file-vector-2',
    mimeTypes: [FilesApi.MimeType.SVG_XML],
    color: DEFAULT_COLOR,
    kind: FileKind.SVG
  },
  {
    label: 'PDF',
    icon: 'file-acrobat',
    mimeTypes: [FilesApi.MimeType.PDF],
    color: '#D20000',
    kind: FileKind.PDF
  },
  {
    label: 'Rich Text',
    icon: 'file-new-2',
    mimeTypes: [FilesApi.MimeType.WORD, FilesApi.MimeType.OFFICE_WORD],
    color: '#20549F',
    kind: FileKind.RichText
  },
  {
    label: 'Spreadsheet',
    icon: 'file-table',
    mimeTypes: [FilesApi.MimeType.EXCEL, FilesApi.MimeType.OFFICE_EXCEL],
    color: '#007641',
    kind: FileKind.Spreadsheet
  },
  {
    label: 'Presentation',
    icon: 'file-powerpoint',
    mimeTypes: [
      FilesApi.MimeType.POWERPOINT,
      FilesApi.MimeType.OFFICE_POWERPOINT
    ],
    color: '#E1310C',
    kind: FileKind.Presentation
  },
  {
    label: 'Zip Archive',
    icon: 'file-format-zip',
    mimeTypes: [FilesApi.MimeType.ZIP],
    color: '#FDD835',
    kind: FileKind.Archive
  },
  {
    label: 'Plain Text',
    icon: 'file-notepad',
    mimeTypes: [FilesApi.MimeType.TEXT],
    color: DEFAULT_COLOR,
    kind: FileKind.PlainText
  },
  {
    label: 'Video',
    icon: 'file-video-1',
    mimeTypes: [
      FilesApi.MimeType.MP4,
      FilesApi.MimeType.OGG,
      FilesApi.MimeType.MS_VIDEO,
      FilesApi.MimeType.QUICKTIME
    ],
    color: '#4FC3F7',
    kind: FileKind.Video
  }
];

export interface FilesConfiguration {
  icon?: string;
  color?: string;
  mime_types?: string[];
  file_types?: FileType[];
}

export const ELM_FILE_PROVIDERS = [
  {
    provide: TOKEN_DEFAULT_COLOR,
    useValue: DEFAULT_COLOR
  },
  {
    provide: TOKEN_DEFAULT_ICON,
    useValue: DEFAULT_ICON
  },
  {
    provide: TOKEN_FILE_TYPES,
    useValue: FILE_TYPES
  },
  {
    provide: TOKEN_IMAGE_MIME_TYPES,
    useValue: IMAGE_MIME_TYPES
  }
];
