<p *ngIf="label" class="field-label elm-form-field-label">
  <ng-content select="elm-upload-label, [elm-upload-label]"> </ng-content>
</p>

<div class="wrapper" [class.has-files]="hasFiles" [class.dragover]="dragover">
  <input
    type="file"
    #fileTrigger
    [disabled]="disabled"
    [accept]="commaSeparatedAccept"
    [multiple]="multiple"
    (change)="!disabled && onFilesChange(fileTrigger.files)"
  />

  <div class="placeholder" *ngIf="!hasFiles; else filesView">
    <svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="64px" height="64px" viewBox="0 0 24 24">
      <g>
        <path
          d="M21.383,7.914c-0.96-0.926-2.203-1.442-3.53-1.472c-1.24-2.339-3.699-3.833-6.353-3.833c-3.617,0-6.648,2.694-7.123,6.228
		C3.349,8.76,2.323,9.079,1.509,9.741C0.55,10.52,0,11.676,0,12.912c0,1.183,0.365,2.144,1.087,2.854
		C2.302,16.963,4.099,17,4.309,17c0.009,0,0.015,0,0.018,0h2.863c0.276,0,0.5-0.224,0.5-0.5S7.466,16,7.189,16H4.32
		c-0.001,0-0.001,0-0.002,0c-0.066,0-1.587-0.012-2.534-0.951C1.264,14.534,1,13.815,1,12.912c0-0.934,0.415-1.807,1.139-2.395
		c0.726-0.588,1.664-0.815,2.583-0.625c0.142,0.03,0.291-0.004,0.406-0.092c0.115-0.088,0.187-0.222,0.195-0.367
		C5.517,6.167,8.23,3.608,11.5,3.608c2.376,0,4.571,1.393,5.593,3.549c0.086,0.181,0.227,0.295,0.471,0.286
		c1.173-0.05,2.283,0.379,3.125,1.19C21.534,9.449,22,10.543,22,11.717c0,3.723-3.712,4.266-3.805,4.283h-2.396
		c-0.276,0-0.5,0.224-0.5,0.5s0.224,0.5,0.5,0.5l2.461-0.004c0.048-0.006,4.74-0.67,4.74-5.279C23,10.27,22.426,8.919,21.383,7.914z
		"
        />
        <path
          d="M11.854,10.45c-0.047-0.046-0.103-0.083-0.163-0.108c-0.123-0.051-0.26-0.051-0.383,0
		c-0.061,0.025-0.116,0.062-0.163,0.108l-2.999,3c-0.195,0.195-0.195,0.512,0,0.707s0.512,0.195,0.707,0L11,12.01v9.793
		c0,0.276,0.224,0.5,0.5,0.5s0.5-0.224,0.5-0.5V12.01l2.146,2.146c0.098,0.098,0.226,0.146,0.354,0.146s0.256-0.049,0.354-0.146
		c0.195-0.195,0.195-0.512,0-0.707L11.854,10.45z"
        />
      </g>
    </svg>

    <h3 class="elm-headline elm-fg-7">
      <ng-content
        *ngIf="placeholderTitle; else defaultPlaceholderTitle"
        select="elm-upload-placeholder-title, [elm-upload-placeholder-title]"
      >
      </ng-content>

      <ng-template #defaultPlaceholderTitle>
        {{ multiple ? 'Upload file(s)' : 'Upload file' }}
      </ng-template>
    </h3>

    <p class="elm-body-1 elm-fg-6 elm-no-margin">
      <ng-content
        *ngIf="placeholderSubtitle; else defaultPlaceholderSubtitle"
        select="elm-upload-placeholder-subtitle, [elm-upload-placeholder-subtitle]"
      >
      </ng-content>

      <ng-template #defaultPlaceholderSubtitle>
        {{ multiple ? 'Upload file(s)' : 'Upload file' }}
      </ng-template>
    </p>
  </div>
</div>

<p class="elm-caption errors-wrapper" *ngIf="errors?.length; else hints">
  <span>{{ errors[0] }}</span>
</p>

<ng-template #hints>
  <p *ngIf="hint" class="field-help-text elm-caption elm-fg-5 elm-no-margin">
    <ng-content select="elm-upload-hint, [elm-upload-hint]"> </ng-content>
  </p>
</ng-template>

<ng-template #filesView>
  <elm-upload-files
    [files]="files"
    [disabled]="disabled"
    (retry)="retry($event)"
    (cancel)="cancel($event)"
    (remove)="remove($event)"
    (view)="view($event)"
  >
  </elm-upload-files>

  <div *ngIf="multiple && hasFiles">
    <hr />
    <button mat-button [disabled]="disabled" (click)="openFilePicker()">
      <span class="add-file-icon">
        <svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="16px" height="16px" viewBox="0 0 24 24">
          <path
            d="M19.647,3.384C17.479,1.207,14.595,0.006,11.5,0C5.171,0,0.013,5.148,0.001,11.477c-0.006,3.072,1.184,5.962,3.352,8.139
	c2.168,2.176,5.054,3.378,8.126,3.384h0.023C17.829,23,22.988,17.851,23,11.521C23.006,8.45,21.815,5.56,19.647,3.384z M11.501,22.5
	L11.501,22.5L11.501,22.5L11.501,22.5z M17.5,12.012v-0.006L12,12v5.5c0,0.276-0.225,0.5-0.5,0.5c-0.276,0-0.5-0.224-0.5-0.5v-5.495
	L5.499,12c-0.276-0.001-0.5-0.225-0.499-0.501C5,11.224,5.224,11,5.5,11v-0.005L11,11V5.5C11,5.224,11.224,5,11.5,5
	C11.775,5,12,5.224,12,5.5v5.494L17.501,11c0.275,0.012,0.5,0.235,0.499,0.512C18,11.788,17.776,12.012,17.5,12.012z"
          />
        </svg>
      </span>
      <span class="elm-title elm-fg-7 elm-no-margin">Add more files</span>
    </button>
  </div>
</ng-template>
