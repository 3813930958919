import { Injectable } from '@angular/core';
import { ApiFile } from '@element451-libs/api451';
import {
  Files,
  getThumbnail,
  isNativeFile,
  readBlob
} from '@element451-libs/utils451/files';
import { uniqueId } from 'lodash';
import { Observable, of } from 'rxjs';

export enum UPLOAD_FILE_STATE {
  START,
  PROGRESS,
  FINISHED,
  FINISHED_IMMUTABLE,
  FAILED,
  CANCELLED,
  INVALID
}

export function UFID() {
  return uniqueId('file-adapter');
}

interface CreateOptions {
  immutable: boolean;
}

@Injectable({
  providedIn: 'root'
})
export class FileAdapterFactory {
  constructor(private files: Files) {}

  create<T extends ApiFile | File = File | ApiFile>(
    file: T,
    options: Partial<CreateOptions> = {}
  ): FileAdapter<T> {
    return (
      isNativeFile(file)
        ? this.createFromNativeFile(file as File)
        : this.createFromApiFileModel(file as ApiFile, options)
    ) as FileAdapter<T>;
  }

  protected createFromNativeFile(file: File): FileAdapter<File> {
    const adapter = new FileAdapter(file);

    adapter.isNative = true;
    adapter.ufid = UFID();
    adapter.name = file.name;
    adapter.color = this.files.getColorByMimeType(file.type);
    adapter.icon = this.files.getIconByMimeType(file.type);
    adapter.size = file.size;
    adapter.mimeType = file.type;

    if (this.files.isImage(file.type)) {
      adapter.thumbnail$ = readBlob(file);
    }

    return adapter;
  }

  protected createFromApiFileModel(
    file: ApiFile,
    options: Partial<CreateOptions> = {}
  ): FileAdapter<ApiFile> {
    const adapter = new FileAdapter(file);

    adapter.state = options.immutable
      ? UPLOAD_FILE_STATE.FINISHED_IMMUTABLE
      : UPLOAD_FILE_STATE.FINISHED;
    adapter.isNative = false;
    adapter.ufid = UFID();
    adapter.name = file.name;
    adapter.color = this.files.getColorByMimeType(file.mime_type);
    adapter.icon = this.files.getIconByMimeType(file.mime_type);
    adapter.size = file.size;
    adapter.mimeType = file.mime_type;

    adapter.thumbnail$ = this.files.isImage(file.mime_type)
      ? of(getThumbnail(file))
      : of(null);

    return adapter;
  }
}

export class FileAdapter<T extends File | ApiFile = File | ApiFile> {
  state: UPLOAD_FILE_STATE = UPLOAD_FILE_STATE.START;
  progress: number;
  name: string;
  size: number;
  color: string;
  icon: string;
  thumbnail$: Observable<string>;
  ufid: string; // unique file id - used internally
  isNative: boolean;
  mimeType: string;

  constructor(public file: T) {}

  clone(): FileAdapter<T> {
    const adapter = new FileAdapter(this.file);

    adapter.isNative = this.isNative;
    adapter.progress = this.progress;
    adapter.state = this.state;
    adapter.name = this.name;
    adapter.size = this.size;
    adapter.color = this.color;
    adapter.icon = this.icon;
    adapter.thumbnail$ = this.thumbnail$;
    adapter.ufid = this.ufid;
    adapter.mimeType = this.mimeType;

    return adapter;
  }
}
