/* eslint-disable @angular-eslint/no-output-on-prefix */

import { coerceBooleanProperty } from '@angular/cdk/coercion';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  forwardRef,
  Input,
  OnChanges,
  OnDestroy,
  Output,
  SimpleChanges,
  ViewEncapsulation
} from '@angular/core';
import {
  ControlValueAccessor,
  NG_VALIDATORS,
  NG_VALUE_ACCESSOR,
  UntypedFormControl,
  UntypedFormGroup,
  Validator
} from '@angular/forms';
import {
  MAT_FORM_FIELD_DEFAULT_OPTIONS,
  MatFormFieldDefaultOptions
} from '@angular/material/form-field';
import {
  BehaviorSubject,
  merge,
  Observable,
  Subject,
  Subscription
} from 'rxjs';
import {
  debounceTime,
  distinctUntilChanged,
  startWith,
  takeUntil,
  tap
} from 'rxjs/operators';
import { IFieldWithData, IFormData } from '../../api-data';
import {
  ConditionalsService,
  IConditionalStreamValue
} from '../../conditional';
import { ErrorsService } from '../../errors';
import { EventBusService, EventHandler } from '../../event-bus';
import { FiltersService } from '../../filters';
import { FormGeneratorService } from '../../form-generator';
import { DynamicFormModel } from '../../models';
import { FormDataParserService } from '../../parser';
import { DynamicFormValueVisitor } from '../../prepare';
import { PrepopulateVisitor } from '../../prepopulate';
import { DynamicFormUploadConfig } from '../dynamic-field/dynamic-controls/file/config';
import { convertToFieldsWithData } from './convert-to-fields-with-data';

export interface DynamicFormOptions {
  uploadConfig: DynamicFormUploadConfig; // config for setting up dropzone, url property is required
}
const options: MatFormFieldDefaultOptions = {
  floatLabel: 'always',
  subscriptSizing: 'dynamic'
};
export const MatFormFieldProvider = {
  provide: MAT_FORM_FIELD_DEFAULT_OPTIONS,
  useValue: options
};

@Component({
  selector: 'lum-df-form, elm-df-form',
  styleUrls: ['./dynamic-form.component.scss'],
  templateUrl: './dynamic-form.component.html',
  host: {
    class: 'lum-df-form elm-df-form'
  },
  exportAs: 'elmDfForm',
  providers: [
    // each form has it's own conditionals service
    // because the service caches controls so it doesn't need
    // to recreate them from scratch
    ConditionalsService,

    // each form has it's own filters service
    FiltersService,

    // each form has it's own event bus for communication with
    // children controls inside it
    EventBusService,

    MatFormFieldProvider,
    // angular forms configuration
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => DynamicFormComponent),
      multi: true
    },
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => DynamicFormComponent),
      multi: true
    }
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None
})
export class DynamicFormComponent
  extends EventHandler
  implements OnDestroy, OnChanges, ControlValueAccessor, Validator
{
  private _destroy: Subject<any> = new Subject<any>();

  private _formSub: Subscription;

  private _formValue: BehaviorSubject<any> = new BehaviorSubject(null);

  @Input()
  form: IFormData;

  @Input()
  data: IFieldWithData[]; // data for prepopulation

  @Input()
  options: DynamicFormOptions;

  @Output()
  onFormValueChanged = new EventEmitter<any>();

  @Output()
  onFormStatusChanged = new EventEmitter<string>();

  @Output()
  onConditionalChanged = new EventEmitter<IConditionalStreamValue>();

  @Output()
  onFiltersChanged = new EventEmitter<any>();

  @Output()
  onSubmit = new EventEmitter<any>();

  // when form has been rendered on the screen
  @Output()
  formChanged = new EventEmitter<any>();

  get value(): any {
    // To be backwards compatible
    const { fields } = this.valueWithFiles;
    return { fields };
  }

  get valueWithFiles() {
    const { fields, files } = this._formValueVisitor.visit(
      this.formModel,
      this._formValue.value
    );
    return { fields, files };
  }

  get valid(): boolean {
    return coerceBooleanProperty(this.formGroup?.valid);
  }

  get invalid(): boolean {
    return coerceBooleanProperty(this.formGroup?.invalid);
  }

  get disabled() {
    return coerceBooleanProperty(this.formGroup?.disabled);
  }

  formModel: DynamicFormModel;

  formGroup: UntypedFormGroup;

  propagateChange: Function = (_: any) => {};

  propagateTouched: Function = (_: any) => {};

  constructor(
    private _parser: FormDataParserService,
    private _generator: FormGeneratorService,
    private _conditionals: ConditionalsService,
    _eventBus: EventBusService,
    private _formValueVisitor: DynamicFormValueVisitor,
    private _prepopulateVisitor: PrepopulateVisitor,
    private _errors: ErrorsService,
    private _filters: FiltersService,
    private _cd: ChangeDetectorRef,
    private _elementRef: ElementRef
  ) {
    super(_eventBus);
  }

  writeValue(value: any): void {
    this.data = convertToFieldsWithData(value, this.form);
  }

  registerOnChange(fn: Function): void {
    this.propagateChange = fn;
  }

  validate(_: UntypedFormControl) {
    return this.formGroup && this.formGroup.status === 'INVALID'
      ? { DynamicFormError: true }
      : null;
  }

  registerOnTouched(fn: Function): void {
    this.propagateTouched = fn;
  }

  setDisabledState(isDisabled: boolean) {
    isDisabled ? this.formGroup.disable() : this.formGroup.enable();
  }

  reset(data: any): void {
    this.formGroup.reset(undefined, { onlySelf: true });

    if (data) {
      this._prepopulateVisitor.visit(data, this.formGroup, this.formModel);
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    if (!!changes['form']) {
      this._tearDown();

      Promise.resolve(null).then(() => {
        this._setUp();
      });
    }

    if (!!changes['data'] && this.data && this.formGroup) {
      this._prepopulateVisitor.visit(this.data, this.formGroup, this.formModel);
    }
  }

  ngOnDestroy() {
    super.ngOnDestroy();
    this._tearDown();
  }

  handleSubmit() {
    if (this.invalid) {
      this._errors.showFormErrors();
    } else {
      const value = this.valueWithFiles;
      this.onSubmit.emit(value);
    }
  }

  scrollToFirstError() {
    setTimeout(() => {
      const errorElements: HTMLElement[] =
        this._elementRef.nativeElement.querySelectorAll('.lum-df-error');
      if (errorElements.length) {
        errorElements[0].scrollIntoView({
          behavior: 'smooth',
          block: 'center'
        });
      }
    });
  }

  // combine all observables into one so there is one starting and ending point
  // useful when the component is destroyed or form is replaced so we can unsubscribe
  // only on one place instead of to keep the reference to all subscriptions
  private connectFormFeatures(
    model: DynamicFormModel,
    form: UntypedFormGroup
  ): Observable<any> {
    if (!form) {
      return null;
    }
    const value$ = this.createValueFeature(model, form);
    const status$ = this.createStatusFeature(form);
    const conditional$ = this.createConditionalFeature(model, form);
    const selfFilters$ = this.createSelfFiltersFeature(model, form);
    const targetFilters$ = this.createTargetFiltersFeature(model, form);

    const allFeatures = [
      value$,
      status$,
      conditional$,
      selfFilters$,
      targetFilters$
    ];

    return merge(...allFeatures);
  }

  private createValueFeature(model: DynamicFormModel, form: UntypedFormGroup) {
    return form.valueChanges.pipe(
      startWith(form.value),
      debounceTime(0),
      tap(formData => this.propagateChange(formData)),
      tap(formData => this._formValue.next(formData)),
      tap(formData =>
        this.onFormValueChanged.emit({
          formData,
          formInstance: this.formGroup
        })
      )
    );
  }

  private createStatusFeature(form: UntypedFormGroup) {
    return form.statusChanges.pipe(
      startWith(form.status),
      distinctUntilChanged<string>(),
      tap(status => this.onFormStatusChanged.emit(status))
    );
  }

  private createConditionalFeature(
    model: DynamicFormModel,
    form: UntypedFormGroup
  ) {
    return this._conditionals
      .start(model, form)
      .pipe(tap(val => this.onConditionalChanged.emit(val as any)));
  }

  private createSelfFiltersFeature(
    model: DynamicFormModel,
    form: UntypedFormGroup
  ) {
    return this._filters
      .startSelfFilters(model, form)
      .pipe(tap(data => this.onFiltersChanged.emit(data)));
  }

  private createTargetFiltersFeature(
    model: DynamicFormModel,
    form: UntypedFormGroup
  ) {
    return this._filters
      .startTargetFilters(model, form)
      .pipe(tap(data => this.onFiltersChanged.emit(data)));
  }

  private _setUp(): void {
    this.formModel = this._parser.parse(this.form);
    this.formGroup = this._generator.generate(this.formModel);

    // by subscribing to this stream we kick off the functionality
    this._formSub = this.connectFormFeatures(this.formModel, this.formGroup)
      .pipe(takeUntil(this._destroy))
      .subscribe(_ => this._cd.markForCheck());

    if (this.data) {
      this._prepopulateVisitor.visit(this.data, this.formGroup, this.formModel);
    }

    this.formChanged.emit({
      form: this.formGroup,
      model: this.formModel
    });
  }

  private _tearDown() {
    this._destroy.next(true);
    this._conditionals.flush();
    this.formModel = null;
    this.formGroup = new UntypedFormGroup({});

    if (this._formSub) {
      this._formSub.unsubscribe();
    }
  }
}
