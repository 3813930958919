export enum FileKind {
  Image = 'image',
  SVG = 'svg',
  PDF = 'pdf',
  RichText = 'rich_text',
  Spreadsheet = 'spreadsheet',
  Presentation = 'presentation',
  Archive = 'archive',
  PlainText = 'plain_text',
  Video = 'video'
}
export interface FileType {
  label: string;
  icon: string;
  color: string;
  mimeTypes: string[];
  kind: FileKind;
}

export interface MimeTypeMap {
  [key: string]: FileType;
}
