<div class="elm-pg-container" [ngClass]="{ 'lum-layout-column': isLtMd }" editableGroup>
  <ng-content select="elm-pg-overlay"></ng-content>

  <div fxLayout class="elm-pg-above-overlay">
    <div
      [fxFlex.xs]="_GRID.col[12]"
      [fxFlex]="isCenterAligned || isLtMd ? _GRID.col[8] : _GRID.col[12]"
      [fxFlexOffset]="isCenterAligned || isLtMd ? _GRID.col[2] : 0"
      fxFlexOffset.xs="0"
    >
      <div fxLayout="isCenterAligned || isLtMd ? 'column' : 'row'" [class.pg-row-inline]="!(isCenterAligned || isLtMd)">
        <div
          [fxFlex]="isCenterAligned || isLtMd ? _GRID.col[12] : _GRID.col[6]"
          fxFlexOrder.lt-md="2"
          [fxFlexOrder]="isRightAligned ? 1 : 2"
        >
          <div
            class="feature-teaser-content"
            [ngClass]="{
              'elm-feature-teaser-padding-left': isLeftAligned,
              'elm-feature-teaser-padding-right': isRightAligned,
              'lum-text-center': isCenterAligned || isLtMd
            }"
          >
            <h2
              class="lum-display-2 elm-dynamic-wysiwyg-link-color-wrapper"
              [ngClass]="{
                'lum-fg-dark-gray': isLightTheme,
                'lum-fg-white': isDarkTheme
              }"
              editable="title"
              *ngIf="elements.title"
              [innerHTML]="title | elmSafeHtml"
            ></h2>

            <h4
              class="lum-display-4 elm-dynamic-wysiwyg-link-color-wrapper"
              [ngClass]="{
                'lum-fg-dark-gray': isLightTheme,
                'lum-fg-white': isDarkTheme
              }"
              editable="subtitle"
              *ngIf="elements.subtitle"
              [innerHTML]="subtitle | elmSafeHtml"
            ></h4>

            <hr *ngIf="elements.divider" [ngStyle]="{ 'background-color': separatorColor }" />

            <p
              class="lum-body-1 elm-dynamic-wysiwyg-link-color-wrapper"
              [ngClass]="{
                'lum-fg-normal-gray': isLightTheme,
                'lum-fg-light-gray': isDarkTheme
              }"
              editable="text"
              *ngIf="elements.text"
              [innerHTML]="text | elmSafeHtml"
            ></p>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
