import { InjectionToken, Type } from '@angular/core';
import { PageRowType } from './page-row-type';
import { RowBase } from './row-base';

import { BillboardAppRowComponent } from './billboard-app';
import { BillboardCtaRowComponent } from './billboard-cta';
import { BillboardFormRowComponent } from './billboard-form';
import { BillboardFormCenteredRowComponent } from './billboard-form-centered';
import { CarouselRowComponent } from './carousel';
import { CtaRowComponent } from './cta';
import { DiscoveryRowComponent } from './discovery';
import { DividerRowComponent } from './divider';
import { FeatureTeaserRowComponent } from './feature-teaser';
import { FooterRowComponent } from './footer';
import { ImageRowComponent } from './image';
import { IntroRowComponent } from './intro';
import { MapRowComponent } from './map';
import { PrepopulatedCenteredRowComponent } from './prepopulated-centered';
import { PrepopulatedLeftRowComponent } from './prepopulated-left';
import { QuoteRowComponent } from './quote';
import { SmartTeaserRowComponent } from './smart-teaser';
import { SocialFeedRowComponent } from './social-feed';
import { TeaserRowComponent } from './teaser';
import { TextRowComponent } from './text';
import { VideoRowComponent } from './video';

export const ROWS = [
  BillboardAppRowComponent,
  BillboardCtaRowComponent,
  BillboardFormRowComponent,
  BillboardFormCenteredRowComponent,
  CarouselRowComponent,
  CtaRowComponent,
  DividerRowComponent,
  FeatureTeaserRowComponent,
  FooterRowComponent,
  ImageRowComponent,
  IntroRowComponent,
  MapRowComponent,
  PrepopulatedCenteredRowComponent,
  PrepopulatedLeftRowComponent,
  QuoteRowComponent,
  SmartTeaserRowComponent,
  SocialFeedRowComponent,
  TeaserRowComponent,
  VideoRowComponent,
  DiscoveryRowComponent,
  TextRowComponent
];

export type RowsSetup = { [key in string]: { type: Type<RowBase> } };

export const ROWS_REGISTRY: RowsSetup = {
  [PageRowType.BillboardAppType]: { type: BillboardAppRowComponent },
  [PageRowType.BillboardCtaType1]: { type: BillboardCtaRowComponent },
  [PageRowType.BillboardCtaType2]: { type: BillboardCtaRowComponent },
  [PageRowType.BillboardCtaType3]: { type: BillboardCtaRowComponent },
  [PageRowType.BillboardCtaType4]: { type: BillboardCtaRowComponent },
  [PageRowType.BillboardCtaType5]: { type: BillboardCtaRowComponent },
  [PageRowType.BillboardCtaType6]: { type: BillboardCtaRowComponent },
  [PageRowType.BillboardFormType1]: { type: BillboardFormRowComponent },
  [PageRowType.BillboardFormType2]: { type: BillboardFormRowComponent },
  [PageRowType.BillboardFormType3]: { type: BillboardFormRowComponent },
  [PageRowType.BillboardFormType4]: { type: BillboardFormRowComponent },
  [PageRowType.BillboardFormType5]: { type: BillboardFormRowComponent },
  [PageRowType.BillboardFormType6]: { type: BillboardFormRowComponent },
  [PageRowType.BillboardFormCenteredType1]: {
    type: BillboardFormCenteredRowComponent
  },
  [PageRowType.BillboardFormCenteredType2]: {
    type: BillboardFormCenteredRowComponent
  },
  [PageRowType.BillboardFormCenteredType3]: {
    type: BillboardFormCenteredRowComponent
  },
  [PageRowType.BillboardFormCenteredType4]: {
    type: BillboardFormCenteredRowComponent
  },
  [PageRowType.BillboardFormCenteredType5]: {
    type: BillboardFormCenteredRowComponent
  },
  [PageRowType.BillboardFormCenteredType6]: {
    type: BillboardFormCenteredRowComponent
  },
  [PageRowType.CarouselType1]: { type: CarouselRowComponent },
  [PageRowType.CarouselType2]: { type: CarouselRowComponent },
  [PageRowType.Cta]: { type: CtaRowComponent },
  [PageRowType.Divider]: { type: DividerRowComponent },
  [PageRowType.FeatureTeaser]: { type: FeatureTeaserRowComponent },
  [PageRowType.Footer]: { type: FooterRowComponent },
  [PageRowType.Image]: { type: ImageRowComponent },
  [PageRowType.Intro]: { type: IntroRowComponent },
  [PageRowType.Map]: { type: MapRowComponent },
  [PageRowType.PrepopulatedCentered]: {
    type: PrepopulatedCenteredRowComponent
  },
  [PageRowType.PrepopulatedLeft]: { type: PrepopulatedLeftRowComponent },
  [PageRowType.Quote]: { type: QuoteRowComponent },
  [PageRowType.SmartTeaser]: { type: SmartTeaserRowComponent },
  [PageRowType.SocialFeed]: { type: SocialFeedRowComponent },
  [PageRowType.Teaser]: { type: TeaserRowComponent },
  [PageRowType.Video]: { type: VideoRowComponent },
  [PageRowType.Discovery]: { type: DiscoveryRowComponent },
  [PageRowType.Text]: { type: TextRowComponent }
};

export const RowsRegistry = new InjectionToken<RowsSetup>(
  'Editor Rows Registry'
);
