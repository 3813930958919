import { CommonModule } from '@angular/common';
import { ModuleWithProviders, NgModule, Type } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MatDialogModule } from '@angular/material/dialog';
import { MatTooltipModule } from '@angular/material/tooltip';
import { ColorModule } from '@element451-libs/utils451/color';
import { TokensModule } from '@element451-libs/utils451/tokens';
import { VideoPreviewModule } from '@element451-libs/utils451/video';
import { TranslocoModule } from '@jsverse/transloco';
// components
import { ActionDirective, PageClickStrategy } from './action';
import {
  BillboardAppComponent,
  BillboardCtaComponent,
  BillboardFormCenteredComponent,
  BillboardFormComponent
} from './billboard';
import { BtnModule } from './btn';
import { CarouselModule } from './carousel';
import {
  // directives
  FlexColumnDecoratorDirective,
  FlexRowDecoratorDirective,
  // configuration
  PAGE451_COLORS,
  PAGE451_GRID
} from './core';
import { CtaComponent } from './cta';
import { DiscoveryComponent } from './discovery';
import { DividerComponent } from './divider';
import { Page451EditorCoreModule } from './editor';
import { FeatureTeaserComponent } from './feature-teaser';
import { FooterComponent } from './footer';
import { FormComponent } from './form';
import { FormPlaceholderComponent } from './form-placeholder';
import { HeaderComponent } from './header';
import { ImageComponent } from './image';
import { ImagePlaceholderComponent } from './image-placeholder';
import { IntroComponent } from './intro';
import { LinkDirective } from './link';
import { MapComponent } from './map';
import { MediaDialogComponent } from './media-dialog';
import { NavigationComponent } from './navigation';
import { OverlayComponent } from './overlay';
import {
  PrepopulateCenteredComponent,
  PrepopulateComponent,
  PrepopulateFormComponent,
  PrepopulateLeftComponent
} from './prepopulate';
import { QuoteComponent } from './quote';
import { Page451ComponentsSharedModule } from './shared';
import { SmartTeaserComponent } from './smart-teaser';
import { SocialFeedModule } from './social-feed';
import { SocialNetworksComponent } from './social-networks';
import {
  TeaserBlockComponent,
  TeaserCardComponent,
  TeaserComponent
} from './teaser';
import { TextComponent } from './text';
import { VideoComponent } from './video';

const TEASER_COMPONENTS = [
  TeaserBlockComponent,
  TeaserCardComponent,
  TeaserComponent
];

const PREPOPULATE_COMPONENTS = [
  PrepopulateComponent,
  PrepopulateCenteredComponent,
  PrepopulateLeftComponent,
  PrepopulateFormComponent
];

@NgModule({
  imports: [
    CommonModule,
    FlexLayoutModule,
    MatDialogModule,
    CarouselModule,
    BtnModule,
    Page451ComponentsSharedModule,
    Page451EditorCoreModule,
    TokensModule,
    VideoPreviewModule.forRoot(),
    ColorModule,
    TranslocoModule,
    MatTooltipModule
  ],
  exports: [
    Page451EditorCoreModule,
    TokensModule,

    // component modules
    CarouselModule,
    SocialFeedModule,
    BtnModule,

    // components
    ActionDirective,
    BillboardAppComponent,
    BillboardCtaComponent,
    BillboardFormComponent,
    BillboardFormCenteredComponent,
    CtaComponent,
    DiscoveryComponent,
    DividerComponent,
    FeatureTeaserComponent,
    FooterComponent,
    FormComponent,
    FormPlaceholderComponent,
    ImageComponent,
    ImagePlaceholderComponent,
    IntroComponent,
    MapComponent,
    MediaDialogComponent,
    OverlayComponent,
    SmartTeaserComponent,
    QuoteComponent,
    VideoComponent,
    NavigationComponent,
    HeaderComponent,
    LinkDirective,
    TextComponent,
    ...PREPOPULATE_COMPONENTS,
    ...TEASER_COMPONENTS
  ],
  declarations: [
    // internal, needed for grid purposes
    FlexColumnDecoratorDirective,
    FlexRowDecoratorDirective,

    // components
    ActionDirective,
    BillboardAppComponent,
    BillboardCtaComponent,
    BillboardFormComponent,
    BillboardFormCenteredComponent,
    CtaComponent,
    DiscoveryComponent,
    DividerComponent,
    FeatureTeaserComponent,
    FooterComponent,
    FormComponent,
    FormPlaceholderComponent,
    ImagePlaceholderComponent,
    ImageComponent,
    ImagePlaceholderComponent,
    IntroComponent,
    MapComponent,
    MediaDialogComponent,
    OverlayComponent,
    SmartTeaserComponent,
    SocialNetworksComponent,
    QuoteComponent,
    VideoComponent,
    NavigationComponent,
    HeaderComponent,
    LinkDirective,
    TextComponent,
    ...PREPOPULATE_COMPONENTS,
    ...TEASER_COMPONENTS
  ]
})
export class Page451ComponentsModule {
  static forChild(options?: {
    clickStrategy?: Type<PageClickStrategy>;
  }): ModuleWithProviders<Page451ComponentsModule> {
    return {
      ngModule: Page451ComponentsModule,
      providers: [
        (options &&
          options.clickStrategy && {
            provide: PageClickStrategy,
            useClass: options.clickStrategy
          }) ||
          []
      ]
    };
  }

  static forRoot(options?: {
    clickStrategy?: Type<PageClickStrategy>;
  }): ModuleWithProviders<Page451ComponentsModule> {
    return {
      ngModule: Page451ComponentsModule,
      providers: [
        // configuration
        PAGE451_COLORS,
        PAGE451_GRID,
        (options &&
          options.clickStrategy && {
            provide: PageClickStrategy,
            useClass: options.clickStrategy
          }) ||
          []
      ]
    };
  }
}
