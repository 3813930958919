import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component
} from '@angular/core';
import { IPgText } from '@element451-libs/page451-lib';

import { RowBase } from '../row-base';

@Component({
  selector: 'elm-text-row',
  templateUrl: 'text-row.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TextRowComponent extends RowBase<IPgText> {
  constructor(cd: ChangeDetectorRef) {
    super(cd);
  }
}
