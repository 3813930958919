<p class="lum-df-label" [innerHTML]="model | lumDfRequiredAsterisk: fieldControl?.errors"></p>
<mat-form-field *ngFor="let ctrl of array.controls; let i = index" class="lum-mat-component">
  <mat-select
    [formControl]="ctrl"
    (selectionChange)="onChange()"
    [placeholder]="model.placeholder"
    panelClass="lum-df-select-panel"
  >
    <mat-option
      *ngFor="let option of model.options$ | async; trackBy: trackByOptionValue"
      [disabled]="option.disabled"
      [value]="option.value"
    >
      <span [innerHTML]="option?.text"></span>
    </mat-option>
  </mat-select>

  <button mat-icon-button type="button" matSuffix class="elm-size-icon-m" (click)="remove(i)">
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24">
      <path
        d="M11.5 0C5.17 0 .01 5.148 0 11.477c-.006 3.072 1.184 5.962 3.352 8.14C5.52 21.79 8.406 22.993 11.478 23h.022c6.328 0 11.487-5.15 11.5-11.48C23.012 5.18 17.863.013 11.5 0zm0 22.5zm6-10.488L5.5 12c-.277 0-.5-.225-.5-.5 0-.276.224-.5.5-.5v-.012l12 .012c.276.012.5.235.5.512 0 .276-.5.5-.5.5z"
      />
    </svg>
  </button>
</mat-form-field>

<button mat-button type="button" *transloco="let t; read: 'forms451.multipleSelect'" class="svg-button" (click)="add()">
  <svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="16px" height="16px" viewBox="0 0 24 24">
    <path
      d="M19.647,3.384C17.479,1.207,14.595,0.006,11.5,0C5.171,0,0.013,5.148,0.001,11.477c-0.006,3.072,1.184,5.962,3.352,8.139
	c2.168,2.176,5.054,3.378,8.126,3.384h0.023C17.829,23,22.988,17.851,23,11.521C23.006,8.45,21.815,5.56,19.647,3.384z M11.501,22.5
	L11.501,22.5L11.501,22.5L11.501,22.5z M17.5,12.012v-0.006L12,12v5.5c0,0.276-0.225,0.5-0.5,0.5c-0.276,0-0.5-0.224-0.5-0.5v-5.495
	L5.499,12c-0.276-0.001-0.5-0.225-0.499-0.501C5,11.224,5.224,11,5.5,11v-0.005L11,11V5.5C11,5.224,11.224,5,11.5,5
	C11.775,5,12,5.224,12,5.5v5.494L17.501,11c0.275,0.012,0.5,0.235,0.499,0.512C18,11.788,17.776,12.012,17.5,12.012z"
      fill="currentColor"
    />
  </svg>

  {{ t('addBtn', { item: model.placeholder }) }}
</button>
