import { PlatformModule } from '@angular/cdk/platform';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { ElmButtonTextModule } from '@element451-libs/components451/button-text';
import { DatetimeInputModule } from '@element451-libs/components451/datetime-input';
import { ElmMatInputClearModule } from '@element451-libs/components451/mat-input-clear';
import { ElmTimeModule } from '@element451-libs/components451/time';
import { ElmUploadModule } from '@element451-libs/components451/upload';
import { TranslocoModule } from '@jsverse/transloco';
import { RxFor } from '@rx-angular/template/for';
import { MarkdownModule } from 'ngx-markdown';
import { NgxMaskDirective } from 'ngx-mask';
import { asyncValidatorsProvider } from '../../validation/async-validators';
import { RequiredAsteriskPipe } from '../../validation/required-asterisk.pipe';
import { CustomControlsModule } from '../custom-controls/custom-controls.module';
import { EnforcedInputsDirective } from './dynamic-controls/autocomplete-select';
import {
  CONTROLS_MAP,
  DYNAMIC_CONTROLS,
  DynamicControls
} from './dynamic-controls/core';
import { FileEventsDirective } from './dynamic-controls/file';
import { DynamicFieldDirective } from './dynamic-field.directive';
import { DynamicFormErrorModule } from './dynamic-form-error.module';
import { DynamicGridComponent } from './dynamic-grid';
import { MainEventsDirective } from './main-events.directive';
import { MaterialComponentsModule } from './material-components/material-components.module';
import { SearchOptionsPipe } from './search-options.pipe';

@NgModule({
  imports: [
    CommonModule,
    PlatformModule,
    TranslocoModule,
    CustomControlsModule,
    MaterialComponentsModule,
    ReactiveFormsModule,
    FormsModule,
    RxFor,
    DynamicFormErrorModule,
    DatetimeInputModule,
    ElmUploadModule,
    ElmButtonTextModule,
    ElmTimeModule,
    ElmMatInputClearModule,
    MatProgressBarModule,
    DynamicFormErrorModule,

    MarkdownModule.forChild(),
    NgxMaskDirective
  ],
  exports: [
    DynamicFieldDirective,
    FileEventsDirective,
    MainEventsDirective,
    DynamicGridComponent,
    EnforcedInputsDirective
  ],
  declarations: [
    DynamicFieldDirective,
    FileEventsDirective,
    MainEventsDirective,
    DynamicGridComponent,
    EnforcedInputsDirective,
    RequiredAsteriskPipe,
    SearchOptionsPipe,
    ...DYNAMIC_CONTROLS
  ],
  providers: [
    { provide: DynamicControls, useValue: CONTROLS_MAP },
    asyncValidatorsProvider
  ]
})
export class DynamicFieldModule {}
