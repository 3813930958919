<div class="wrapper" *ngLet="file.thumbnail$ | async as thumbnail">
  @if (thumbnail) {
    <div class="thumbnail-wrapper">
      <img [src]="thumbnail" [alt]="title" />
    </div>
  } @else {
    <div class="icon-wrapper" [style.background-color]="color || '#C2C9CC'">
      @switch (fileKind) {
        @case (kind.Image) {
          <svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="24px" height="24px" viewBox="0 0 24 24">
            <path
              d="M19.854,6.147l-6-6C13.76,0.053,13.633,0,13.5,0h-13C0.225,0,0,0.224,0,0.5v23C0,23.776,0.225,24,0.5,24h19
	c0.275,0,0.5-0.224,0.5-0.5v-17C20,6.367,19.947,6.24,19.854,6.147z M7,7.165c1.102,0,2,0.897,2,2c0,1.103-0.898,2-2,2s-2-0.897-2-2
	C5,8.061,5.898,7.165,7,7.165z M15.9,19.964c-0.095,0.126-0.243,0.2-0.4,0.2h-10c-0.173,0-0.334-0.09-0.426-0.237
	c-0.091-0.146-0.099-0.332-0.022-0.486l2.5-5c0.072-0.143,0.209-0.244,0.367-0.27c0.159-0.025,0.32,0.027,0.434,0.14l1.527,1.528
	l2.681-4.913c0.097-0.178,0.295-0.278,0.491-0.257c0.201,0.021,0.37,0.162,0.427,0.355l2.5,8.5
	C16.024,19.675,15.995,19.838,15.9,19.964z M13,7V1l6,6H13z"
            />
          </svg>
        }
        @case (kind.SVG) {
          <svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="24px" height="24px" viewBox="0 0 24 24">
            <g>
              <path
                d="M21.854,6.147l-6-6C15.76,0.053,15.633,0,15.5,0h-13C2.225,0,2,0.224,2,0.5v23C2,23.776,2.225,24,2.5,24h19
		c0.275,0,0.5-0.224,0.5-0.5v-17C22,6.367,21.947,6.24,21.854,6.147z M17,12.5c0,0.276-0.224,0.5-0.5,0.5H16v5h0.5
		c0.276,0,0.5,0.224,0.5,0.5v2c0,0.276-0.224,0.5-0.5,0.5h-2c-0.276,0-0.5-0.224-0.5-0.5V20H9v0.5C9,20.776,8.776,21,8.5,21h-2
		C6.224,21,6,20.776,6,20.5v-2C6,18.224,6.224,18,6.5,18H7v-5H6.5C6.224,13,6,12.776,6,12.5v-2C6,10.224,6.224,10,6.5,10h2
		C8.776,10,9,10.224,9,10.5V11h5v-0.5c0-0.276,0.224-0.5,0.5-0.5h2c0.276,0,0.5,0.224,0.5,0.5V12.5z M15,7V1l6,6H15z"
              />
              <path
                d="M14,12.5V12H9v0.5C9,12.776,8.776,13,8.5,13H8v5h0.5C8.776,18,9,18.224,9,18.5V19h5v-0.5c0-0.276,0.224-0.5,0.5-0.5H15v-5
		h-0.5C14.224,13,14,12.776,14,12.5z"
              />
            </g>
          </svg>
        }
        @case (kind.PDF) {
          <svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="24px" height="24px" viewBox="0 0 24 24">
            <g>
              <path
                d="M10.135,14.049c0.439-0.24,0.887-0.454,1.339-0.643c-0.445-0.279-0.875-0.585-1.286-0.917
		C10.2,13.023,10.182,13.545,10.135,14.049z"
              />
              <path
                d="M6.896,17.924c-0.132,0.162-0.17,0.303-0.116,0.417c0.122,0.257,0.595,0.316,0.83-0.005
		c0.457-0.627,0.818-1.344,1.079-2.137C7.734,16.949,7.144,17.619,6.896,17.924z"
              />
              <path
                d="M8.122,7.429c-0.107,0-0.269,0.087-0.359,0.25C7.67,7.842,7.682,8.021,7.796,8.209c0.336,0.554,0.702,1.075,1.093,1.562
		C8.673,8.672,8.406,7.894,8.304,7.619C8.234,7.429,8.152,7.429,8.122,7.429z"
              />
              <path
                d="M16.681,13.434c-0.849-0.033-1.673,0.032-2.503,0.193c1.049,0.389,1.984,0.569,2.657,0.652l0.07,0.004
		c0.266,0,0.299-0.238,0.301-0.342C17.208,13.86,17.19,13.454,16.681,13.434z"
              />
              <path
                d="M21.854,6.147l-6-6C15.76,0.053,15.632,0,15.5,0h-13C2.224,0,2,0.224,2,0.5v23C2,23.776,2.224,24,2.5,24h19
		c0.276,0,0.5-0.224,0.5-0.5v-17C22,6.367,21.947,6.24,21.854,6.147z M16.713,15.271c-0.983-0.121-2.477-0.433-4.096-1.229
		c-0.913,0.312-1.808,0.738-2.664,1.271c-0.277,1.379-0.793,2.594-1.535,3.613c-0.299,0.409-0.746,0.644-1.226,0.644
		c-0.559,0-1.087-0.322-1.315-0.799c-0.225-0.473-0.137-1.011,0.242-1.479c0.348-0.428,1.312-1.514,2.926-2.586
		c0.156-0.978,0.188-2.041,0.092-3.162c-0.819-0.818-1.558-1.766-2.196-2.816C6.639,8.231,6.62,7.668,6.891,7.188
		c0.259-0.46,0.743-0.759,1.231-0.759c0.507,0,0.926,0.315,1.121,0.843c0.15,0.408,0.647,1.861,0.855,3.812
		c0.79,0.745,1.665,1.376,2.607,1.88c1.32-0.402,2.652-0.582,4.015-0.527c0.994,0.037,1.5,0.797,1.486,1.526
		C18.19,14.792,17.559,15.378,16.713,15.271z M15,7V1l6,6H15z"
              />
            </g>
          </svg>
        }
        @case (kind.RichText) {
          <svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="24px" height="24px" viewBox="0 0 24 24">
            <path
              d="M20.854,6.147l-6-6C14.76,0.053,14.632,0,14.5,0h-11C3.224,0,3,0.224,3,0.5v23C3,23.776,3.224,24,3.5,24h17
	c0.276,0,0.5-0.224,0.5-0.5v-17C21,6.367,20.947,6.24,20.854,6.147z M7.5,7H12c0.276,0,0.5,0.224,0.5,0.5S12.276,8,12,8H7.5
	C7.224,8,7,7.776,7,7.5S7.224,7,7.5,7z M16.5,20h-9C7.224,20,7,19.776,7,19.5S7.224,19,7.5,19h9c0.276,0,0.5,0.224,0.5,0.5
	S16.776,20,16.5,20z M16.5,17h-9C7.224,17,7,16.776,7,16.5S7.224,16,7.5,16h9c0.276,0,0.5,0.224,0.5,0.5S16.776,17,16.5,17z
	 M16.5,14h-9C7.224,14,7,13.776,7,13.5S7.224,13,7.5,13h9c0.276,0,0.5,0.224,0.5,0.5S16.776,14,16.5,14z M16.5,11h-9
	C7.224,11,7,10.776,7,10.5S7.224,10,7.5,10h9c0.276,0,0.5,0.224,0.5,0.5S16.776,11,16.5,11z M14,7V1l6,6H14z"
            />
          </svg>
        }
        @case (kind.Spreadsheet) {
          <svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="24px" height="24px" viewBox="0 0 24 24">
            <g>
              <rect x="7" y="18" width="3" height="2" />
              <rect x="11" y="18" width="6" height="2" />
              <rect x="11" y="12" width="6" height="2" />
              <rect x="11" y="15" width="6" height="2" />
              <rect x="7" y="12" width="3" height="2" />
              <rect x="7" y="15" width="3" height="2" />
              <rect x="11" y="9" width="6" height="2" />
              <rect x="7" y="9" width="3" height="2" />
              <path
                d="M20.854,6.147l-6-6C14.76,0.053,14.632,0,14.5,0h-11C3.224,0,3,0.224,3,0.5v23C3,23.776,3.224,24,3.5,24h17
		c0.276,0,0.5-0.224,0.5-0.5v-17C21,6.367,20.947,6.24,20.854,6.147z M18,21H6V8h12V21z M14,7V1l6,6H14z"
              />
            </g>
          </svg>
        }
        @case (kind.Presentation) {
          <svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="24px" height="24px" viewBox="0 0 24 24">
            <g>
              <path d="M12.742,13.999H12V16h0.742C13.909,16,14,15.234,14,15S13.909,13.999,12.742,13.999z" />
              <path d="M7.742,13.999H7V16h0.742C8.909,16,9,15.234,9,15S8.909,13.999,7.742,13.999z" />
              <path
                d="M21.854,6.147l-6-6C15.76,0.053,15.632,0,15.5,0h-13C2.224,0,2,0.224,2,0.5v23C2,23.776,2.224,24,2.5,24h19
		c0.276,0,0.5-0.224,0.5-0.5v-17C22,6.367,21.947,6.24,21.854,6.147z M7.742,17H7v1.687c0,0.276-0.224,0.5-0.5,0.5
		S6,18.963,6,18.687v-5.188c0-0.276,0.224-0.5,0.5-0.5h1.242C9.293,12.999,10,14.036,10,15C10,15.963,9.293,17,7.742,17z M12.742,17
		H12v1.687c0,0.276-0.224,0.5-0.5,0.5s-0.5-0.224-0.5-0.5v-5.188c0-0.276,0.224-0.5,0.5-0.5h1.242C14.293,12.999,15,14.036,15,15
		C15,15.963,14.293,17,12.742,17z M18.74,14H18v4.688c0,0.276-0.224,0.5-0.5,0.5s-0.5-0.224-0.5-0.5V14h-0.74
		c-0.276,0-0.5-0.224-0.5-0.5s0.224-0.5,0.5-0.5h2.48c0.276,0,0.5,0.224,0.5,0.5S19.017,14,18.74,14z M15,7V1l6,6H15z"
              />
            </g>
          </svg>
        }
        @case (kind.Archive) {
          <svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="24px" height="24px" viewBox="0 0 24 24">
            <g>
              <path d="M16.761,13.812H16.02v1.594h0.741c0.637,0,0.739-0.189,0.739-0.797S17.398,13.812,16.761,13.812z" />
              <path
                d="M21.854,6.147l-6-6C15.76,0.053,15.632,0,15.5,0h-13C2.224,0,2,0.224,2,0.5v23C2,23.776,2.224,24,2.5,24h19
		c0.276,0,0.5-0.224,0.5-0.5v-17C22,6.367,21.947,6.24,21.854,6.147z M9.5,18.999H7.245c-0.168,0-0.326-0.085-0.418-0.226
		c-0.093-0.142-0.108-0.319-0.04-0.474l1.951-4.487H7.245c-0.276,0-0.5-0.224-0.5-0.5s0.224-0.5,0.5-0.5H9.5
		c0.168,0,0.326,0.085,0.418,0.226c0.093,0.142,0.108,0.319,0.04,0.474l-1.951,4.487H9.5c0.276,0,0.5,0.224,0.5,0.5
		C10,18.775,9.776,18.999,9.5,18.999z M13,18.5c0,0.276-0.224,0.5-0.5,0.5S12,18.776,12,18.5v-5.188c0-0.276,0.224-0.5,0.5-0.5
		s0.5,0.224,0.5,0.5V18.5z M16.761,16.406H16.02V18.5c0,0.276-0.224,0.5-0.5,0.5c-0.276,0-0.5-0.224-0.5-0.5v-5.188
		c0-0.276,0.224-0.5,0.5-0.5h1.241c1.154,0,1.739,0.604,1.739,1.797S17.915,16.406,16.761,16.406z M15,7V1l6,6H15z"
              />
            </g>
          </svg>
        }
        @case (kind.PlainText) {
          <svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="24px" height="24px" viewBox="0 0 24 24">
            <path
              d="M21.854,6.147l-6-6C15.76,0.053,15.632,0,15.5,0h-13C2.224,0,2,0.224,2,0.5v23C2,23.776,2.224,24,2.5,24h19
	c0.276,0,0.5-0.224,0.5-0.5v-17C22,6.367,21.947,6.24,21.854,6.147z M8.782,13.999h-0.74v4.688c0,0.276-0.224,0.5-0.5,0.5
	c-0.276,0-0.5-0.224-0.542-0.5v-4.688H6.302c-0.276,0-0.5-0.224-0.5-0.5c0-0.276,0.224-0.5,0.5-0.5h2.48c0.276,0,0.5,0.224,0.5,0.5
	C9.282,13.775,9.059,13.999,8.782,13.999z M13.784,18.471c0.119,0.249,0.014,0.548-0.235,0.667c-0.07,0.033-0.143,0.049-0.215,0.049
	c-0.186,0-0.366-0.104-0.452-0.284l-0.79-1.65l-0.79,1.65c-0.086,0.18-0.265,0.284-0.452,0.284c-0.072,0-0.145-0.016-0.215-0.049
	c-0.249-0.119-0.354-0.418-0.235-0.667l1.138-2.378l-1.138-2.378c-0.119-0.249-0.014-0.548,0.235-0.666
	c0.249-0.121,0.547-0.016,0.667,0.234l0.79,1.65l0.79-1.65c0.119-0.248,0.418-0.356,0.667-0.234
	c0.249,0.118,0.354,0.417,0.235,0.666l-1.138,2.378L13.784,18.471z M17.74,13.999H17v4.688c0,0.276-0.224,0.5-0.5,0.5
	s-0.5-0.224-0.5-0.5v-4.688h-0.74c-0.276,0-0.5-0.224-0.5-0.5c0-0.276,0.224-0.5,0.5-0.5h2.48c0.276,0,0.5,0.224,0.5,0.5
	C18.24,13.775,18.017,13.999,17.74,13.999z M15,7V1l6,6H15z"
            />
          </svg>
        }
        @case (kind.Video) {
          <svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="24px" height="24px" viewBox="0 0 24 24">
            <path
              d="M20.854,6.147l-6-6C14.76,0.053,14.633,0,14.5,0h-11C3.225,0,3,0.224,3,0.5v23C3,23.776,3.225,24,3.5,24h17
	c0.275,0,0.5-0.224,0.5-0.5v-17C21,6.367,20.947,6.24,20.854,6.147z M16.766,13.924l-8,5C8.684,18.975,8.592,19,8.5,19
	c-0.084,0-0.166-0.021-0.242-0.062C8.099,18.849,8,18.682,8,18.5v-10c0-0.182,0.099-0.35,0.258-0.436
	c0.158-0.088,0.354-0.084,0.508,0.014l8,5C16.911,13.167,17,13.327,17,13.5C17,13.672,16.911,13.832,16.766,13.924z M14,7V1l6,6H14z
	"
            />
          </svg>
        }
        @default {
          <svg width="24px" height="24px" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
              d="M5.00065 1.6665C4.08398 1.6665 3.34232 2.4165 3.34232 3.33317L3.33398 16.6665C3.33398 17.5832 4.07565 18.3332 4.99232 18.3332H15.0007C15.9173 18.3332 16.6673 17.5832 16.6673 16.6665V6.6665L11.6673 1.6665H5.00065ZM10.834 7.49984V2.9165L15.4173 7.49984H10.834Z"
              fill="currentColor"
            />
          </svg>
        }
      }
    </div>
  }

  @if (file.name) {
    <div class="info">
      <div class="title elm-body-1 elm-fg-7 elm-no-margin">
        <span>{{ file.name }}</span>
      </div>
      <div class="subtitle elm-caption elm-fg-5 elm-no-margin">{{ file?.size | elmBytesToSize }}</div>
    </div>
  }
</div>
