import {
  ChangeDetectionStrategy,
  Component,
  inject,
  Input
} from '@angular/core';
import { FileKind, Files } from '@element451-libs/utils451/files';
import { FileAdapter } from '../file-adapter';

@Component({
  selector: 'elm-upload-thumbnail',
  templateUrl: './elm-upload-thumbnail.component.html',
  styleUrls: ['./elm-upload-thumbnail.component.scss'],
  host: {
    class: 'elm-upload-thumbnail'
  },
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ElmUploadThumbnailComponent {
  private files = inject(Files);

  kind = FileKind;

  color: string;

  fileKind: FileKind | null = null;

  private _file: FileAdapter;

  @Input() set file(file: FileAdapter) {
    this._file = file;
    if (file.mimeType) {
      this.color = this.files.getColorByMimeType(file.mimeType);
      this.fileKind = this.files.getKindByMimeType(file.mimeType);
    }
  }

  get file() {
    return this._file;
  }
}
