import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { PaymentApiService, responseData } from '@element451-libs/api451';
import { ElmDialogService } from '@element451-libs/components451/dialog';
import { shouldSkipPayment } from '@element451-libs/components451/payment-form';
import { PaymentApi } from '@element451-libs/models451';
import { RouterStateService } from '@element451-libs/utils451/router';
import { mapToPayload } from '@element451-libs/utils451/rxjs';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { exhaustMap, filter, of, switchMap, tap, withLatestFrom } from 'rxjs';
import {
  AlertDialogComponent,
  ApplicationPaymentConfirmationDialogComponent
} from '../../components';
import { AccountService } from '../account';
import { DASHBOARD_ACTIONS, DashboardService } from '../dashboard';
import * as fromDashboard from '../dashboard/dashboard.actions';
import { USER_DOCUMENTS_ACTIONS } from '../user-documents';
import { USER_INFO_REQUESTS_ACTIONS } from '../user-info-requests';
interface PaymentResponse {
  status: 'success' | 'error' | 'cancel';
  msg?: string;
}

@Injectable()
export class PaymentEffects {
  constructor(
    private actions$: Actions<fromDashboard.DashboardAction>,
    private router: Router,
    private dialog: ElmDialogService,
    private routerState: RouterStateService,
    private paymentApi: PaymentApiService,
    private account: AccountService,
    private dashboard: DashboardService
  ) {}

  onDashboardLoaded$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(DASHBOARD_ACTIONS.LOAD_DASHBOARD_SUCCESS),
        withLatestFrom(this.routerState.queryParams$),
        filter(([_, params]: [any, PaymentResponse]) => !!params.status),
        exhaustMap(([_, params]) => this.openDialog(params)),
        tap(_ => {
          this.router.navigate([], {
            queryParams: { status: null, msg: null },
            queryParamsHandling: 'merge'
          });
        })
      ),
    { dispatch: false }
  );

  evaluatePaymentOnDashboardLoad$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(DASHBOARD_ACTIONS.LOAD_DASHBOARD_SUCCESS),
        mapToPayload,
        withLatestFrom(
          this.account.userId$,
          this.dashboard.applicationSubmitted$
        ),
        filter(
          ([{ normalized }, _, submitted]) =>
            !normalized.payment.completed && submitted
        ),
        switchMap(([{ normalized }, userId]) =>
          this.evaluatePaymentStatus(
            normalized.guid,
            normalized.registrationId,
            userId
          )
        )
      ),
    { dispatch: false }
  );

  evaluatePaymentOnDocumentChanges$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(
          USER_DOCUMENTS_ACTIONS.ADD_USER_DOCUMENT,
          USER_DOCUMENTS_ACTIONS.REMOVE_USER_DOCUMENT_SUCCESS,
          USER_INFO_REQUESTS_ACTIONS.CREATE_SUCCESS,
          USER_INFO_REQUESTS_ACTIONS.DELETE_SUCCESS
        ),
        withLatestFrom(
          this.dashboard.applicationSubmitted$,
          this.dashboard.dashboard$,
          this.account.userId$
        ),
        filter(([submitted]) => submitted),
        switchMap(([_, __, dashboard, userId]) =>
          this.evaluatePaymentStatus(
            dashboard.guid,
            dashboard.registrationId,
            userId
          )
        )
      ),
    { dispatch: false }
  );

  private evaluatePaymentStatus(
    appGuid: string,
    registrationId: string,
    userId: string
  ) {
    return this.paymentApi
      .getPaymentInfo({
        nature: PaymentApi.PaymentNature.Application,
        app_guid: appGuid,
        registration_id: registrationId,
        user_id: userId
      })
      .pipe(
        responseData,
        tap(({ payment }) => {
          const skipPayment = shouldSkipPayment(payment, true);
          if (skipPayment) {
            this.dashboard.applicationPaid();
          }
        })
      );
  }

  private openDialog(params: PaymentResponse) {
    switch (params.status) {
      case 'success':
        return this.openPaymentSuccessDialog();
      case 'cancel':
        return this.openPaymentCancelDialog(params.msg);
      case 'error':
        return this.openPaymentErrorDialog(params.msg);
      default: {
        console.warn('Unhandled Payment Response status', params.status);
        return of(true);
      }
    }
  }

  private openPaymentErrorDialog(error: string) {
    return this.dialog.open(AlertDialogComponent, {
      data: {
        title: `Payment Error`,
        content: error || 'Error processing the payment'
      }
    });
  }

  private openPaymentCancelDialog(reason: string) {
    return this.dialog.open(AlertDialogComponent, {
      data: {
        title: `Payment Canceled`,
        content: reason || `Transaction has been canceled`
      }
    });
  }

  private openPaymentSuccessDialog() {
    return this.dialog.open(ApplicationPaymentConfirmationDialogComponent, {
      data: PaymentApi.PaymentMethod.CreditCard
    });
  }
}
