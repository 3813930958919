<div [formGroup]="group" *transloco="let t; read: 'forms451.password'">
  <mat-form-field class="lum-mat-component" [color]="fieldControl.errors && fieldControl.dirty ? 'warn' : ''">
    <mat-label> <span [innerHTML]="model | lumDfRequiredAsterisk: fieldControl?.errors"></span> </mat-label>
    <input matInput [attr.type]="currentInputType" [formControlName]="model.key" (blur)="onBlur()" />

    <button mat-icon-button matSuffix type="button" [attr.aria-label]="t(passwordState)" (click)="togglePassword()">
      @switch (passwordState) {
        @case (STATE.SHOWN) {
          <svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="24px" height="24px" viewBox="0 0 24 24">
            <g>
              <path
                d="M23.913,12.188c-0.031-0.068-0.781-1.699-2.839-3.367c-0.429-0.348-1.058-0.282-1.407,0.148
		c-0.348,0.427-0.281,1.058,0.147,1.405c1.028,0.834,1.654,1.642,1.981,2.139c-1.199,1.453-5.031,5.586-9.795,5.586
		c-0.441,0-0.895-0.036-1.348-0.106c-0.545-0.086-1.057,0.289-1.143,0.835c-0.084,0.544,0.289,1.058,0.834,1.142
		c0.555,0.086,1.111,0.13,1.656,0.13c6.787,0,11.611-6.635,11.813-6.918C24.02,12.891,24.058,12.512,23.913,12.188z"
              />
              <path
                d="M2.874,16.333c0.425,0.349,1.054,0.288,1.407-0.142c0.349-0.428,0.285-1.059-0.144-1.407
		c-1.004-0.82-1.613-1.61-1.935-2.099C3.401,11.234,7.235,7.099,12,7.099c0.307,0,0.621,0.017,0.936,0.051
		c0.55,0.062,1.043-0.336,1.103-0.886c0.061-0.548-0.336-1.041-0.885-1.102C12.768,5.121,12.379,5.099,12,5.099
		c-6.788,0-11.611,6.635-11.814,6.918c-0.205,0.288-0.244,0.663-0.1,0.988C0.116,13.073,0.844,14.679,2.874,16.333z"
              />
              <path
                d="M12.583,16.223c2.205,0,4-1.794,4-4c0-0.417-0.064-0.818-0.184-1.197l-5.013,5.014
		C11.764,16.159,12.167,16.223,12.583,16.223z"
              />
              <path
                d="M8.583,12.223c0,0.298,0.035,0.587,0.097,0.867l4.77-4.77c-0.279-0.061-0.568-0.097-0.866-0.097
		C10.377,8.223,8.583,10.018,8.583,12.223z"
              />
              <path
                d="M23.051,1.548c-0.366-0.373-0.997-0.402-1.396-0.016c-0.006,0.005-0.013,0.009-0.019,0.016l-19.5,19.5
		c-0.353,0.279-0.429,0.989,0,1.414c0.001,0.001,0.003,0.002,0.004,0.004c0.377,0.372,1.029,0.372,1.405,0
		c0.001-0.001,0.003-0.002,0.005-0.004l19.5-19.5C23.443,2.566,23.437,1.934,23.051,1.548z"
              />
            </g>
          </svg>
        }
        @case (STATE.HIDDEN) {
          <svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="24px" height="24px" viewBox="0 0 24 24">
            <g>
              <path
                d="M0.108,12.31C0.325,12.583,5.482,19,12.001,19c6.518,0,11.675-6.417,11.891-6.69c0.145-0.182,0.145-0.439,0-0.62
		C23.676,11.416,18.519,5,12.001,5C5.482,5,0.325,11.416,0.108,11.69C-0.036,11.871-0.036,12.128,0.108,12.31z M12.001,7.5
		c2.48,0,4.5,2.018,4.5,4.5s-2.02,4.5-4.5,4.5c-2.481,0-4.5-2.018-4.5-4.5S9.52,7.5,12.001,7.5z"
              />
              <path
                d="M12.001,15.5c1.93,0,3.5-1.57,3.5-3.5c0-1.931-1.57-3.5-3.5-3.5c-1.931,0-3.5,1.569-3.5,3.5
		C8.501,13.93,10.07,15.5,12.001,15.5z"
              />
            </g>
          </svg>
        }
      }
    </button>
    <mat-hint class="lum-df-hint-wrapper">
      <span [lumDynamicFormError]="fieldControl" [validText]="model.hint || '' | markdown | async"></span>
    </mat-hint>
  </mat-form-field>
</div>
