import { Injectable, RendererFactory2, ViewEncapsulation } from '@angular/core';
import { ApiClient } from '@element451-libs/api451'; // #TODO: Move to models
import { getShellUrl, getSubdomainFromUrl } from '@element451-libs/config451';
import { Conversations451Api } from '@element451-libs/models451';
import { ShellModels } from '@element451-libs/shell/models';
import { DocumentRef } from '@element451-libs/utils451/document';
import { truthy } from '@element451-libs/utils451/rxjs';
import { BehaviorSubject } from 'rxjs';
import { take } from 'rxjs/operators';

const SHELL_SCRIPT_ID = 'shell451';

@Injectable({ providedIn: 'root' })
export class ShellEmbedService {
  private _messengerIframe$ = new BehaviorSubject<HTMLIFrameElement | null>(
    null
  );
  private _boltSearchIframe$ = new BehaviorSubject<HTMLIFrameElement | null>(
    null
  );

  messengerIframe$ = this._messengerIframe$.asObservable().pipe(truthy);

  boltSearchIframe$ = this._boltSearchIframe$.asObservable().pipe(truthy);

  constructor(
    private rendererFactory: RendererFactory2,
    private documentRef: DocumentRef
  ) {}

  init(
    client: ApiClient,
    { messengerEnabled = true, boltSearchEnabled = true },
    devMode?: {
      localMessengerUrl?: string;
      localMessengerConfig?: string;
      localBoltSearchUrl?: string;
    }
  ) {
    const shellRoot = document.querySelector(`script#${SHELL_SCRIPT_ID}`);

    if (shellRoot) {
      console.warn('Shell has already been initialised');
      return;
    }

    const subdom = getSubdomainFromUrl(client.apiUrl);

    const shellUrl = getShellUrl(client.apiUrl, subdom);

    const renderer = this.rendererFactory.createRenderer(
      this.documentRef.body,
      {
        id: '-2',
        encapsulation: ViewEncapsulation.None,
        styles: [],
        data: {}
      }
    );

    const script = renderer.createElement('script');

    script.setAttribute('type', 'text/javascript');
    script.setAttribute('id', SHELL_SCRIPT_ID);
    script.setAttribute('src', shellUrl);
    script.setAttribute('async', 'true');

    if (devMode) {
      if (devMode.localMessengerUrl) {
        //  'http://localhost:63456/'
        script.setAttribute(
          'data-dev-messenger-url',
          devMode.localMessengerUrl
        );
      }
      if (devMode.localMessengerConfig) {
        // 'eyJhcGlVcmwiOiJodHRwczovL3R1LmRldmVsb3BtZW50LmRldi40NTEuaW8vIiwiaG9zdG5hbWUiOiJ0dSIsInN1YmRvbSI6InR1IiwiYXBpVXJsUlQiOiJodHRwOi8vdHUuZGV2ZWxvcG1lbnQucnQtZGV2LjQ1MS5pby8iLCJkZXZBcGlVcmxSVCI6Imh0dHA6Ly9sb2NhbGhvc3Q6MzMzMy8iLCJmZWF0dXJlVG9rZW4iOiJSd005b2lxdERnTkRwWXlhMVZYRTdwR1MwWEdEN0tsVVFNSXJ5R05sIiwiYW5hbHl0aWNzVG9rZW4iOiIzZkZTTVMzRzdJY2RJeXJkRUt2QXV1cHRnVHBNUXNwN0pRMmJwemxMIiwidHlwZSI6InNoZWxsNDUxIn0='
        script.setAttribute('data-dev-config', devMode.localMessengerConfig);
      }

      if (devMode.localBoltSearchUrl) {
        script.setAttribute(
          'data-dev-bolt-search-url',
          devMode.localBoltSearchUrl
        );
      }
    }

    if (!messengerEnabled) {
      script.setAttribute('data-messenger-disabled', 'true');
    }

    if (!boltSearchEnabled) {
      script.setAttribute('data-bolt-search-disabled', 'true');
    }

    renderer.appendChild(this.documentRef.body, script);

    this.listenHandshake(messengerEnabled, boltSearchEnabled);
  }

  customize(config: Conversations451Api.MessengerConfigCustomization) {
    this.sendMessage(ShellModels.IntercommunicationMessage.Customize, {
      config
    });
  }

  authenticate(token: string) {
    this.sendMessage(ShellModels.IntercommunicationMessage.Authenticate, {
      token
    });
  }

  private sendMessage(type: string, payload: any) {
    const iframes = [this._boltSearchIframe$, this._messengerIframe$];

    for (const iframe of iframes) {
      iframe.pipe(truthy, take(1)).subscribe(iframe => {
        const data = {
          type,
          payload
        };

        iframe?.contentWindow?.postMessage(data, '*');
      });
    }
  }

  private listenHandshake(
    messengerEnabled: boolean,
    boltSearchEnabled: boolean
  ) {
    window.addEventListener('message', event => {
      if (event.data.type === ShellModels.IntercommunicationMessage.Handshake) {
        if (messengerEnabled && event.data.appName === 'messenger') {
          const messengerIframe: HTMLIFrameElement =
            this.documentRef.document.querySelector('#messenger-root iframe');
          if (!messengerIframe) {
            console.error(`Messenger iframe doesn't exist`);
          }
          this._messengerIframe$.next(messengerIframe);
        }

        if (boltSearchEnabled && event.data.appName === 'bolt-search') {
          const boltSearchIframe: HTMLIFrameElement =
            this.documentRef.document.querySelector('#bolt-search-root iframe');
          if (!boltSearchIframe) {
            console.error(`Bolt search iframe doesn't exist`);
          }
          this._boltSearchIframe$.next(boltSearchIframe);
        }
      }
    });
  }
}
