<ng-container *ngIf="row">
  <elm-pg-text
    [pageGuid]="row.pageGuid"
    [title]="row.title"
    [subtitle]="row.subtitle"
    [text]="row.text"
    [elements]="row.elements"
    [separatorColor]="row.separatorColor"
    [aligned]="row.aligned"
    [background]="row.background"
    [padding]="row.padding"
    [theme]="row.theme"
    [link]="row.link"
    [ariaLabel]="row.ariaLabel"
  >
    <elm-pg-overlay
      *ngIf="row.overlay; let overlay"
      [type]="overlay.type"
      [value]="overlay.value"
      [opacity]="overlay.opacity"
    >
    </elm-pg-overlay>
  </elm-pg-text>
</ng-container>
