import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Input,
  OnDestroy,
  OnInit,
  Self,
  SkipSelf,
  ViewEncapsulation
} from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { keys } from 'lodash';
import { Subscription } from 'rxjs';
import { filter } from 'rxjs/operators';
import { EventBusService } from '../../../../event-bus';
import { DynamicGroupModel } from '../../../../models';
import { FieldConfigDirective } from '../../../shared';

@Component({
  selector: 'lum-df-group',
  templateUrl: './group.component.html',
  styleUrls: ['./group.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [EventBusService]
})
export class GroupComponent
  extends FieldConfigDirective<DynamicGroupModel>
  implements AfterViewInit, OnInit, OnDestroy
{
  @Input() group: UntypedFormGroup;

  @Input() model: DynamicGroupModel;

  @Input() options: any;

  @Input() data: any;

  @Input() showTitle = true;

  public groupNode: UntypedFormGroup;

  private eventSub: Subscription;

  constructor(
    @SkipSelf() private globalEventBus: EventBusService,
    @Self() private localEventBus: EventBusService,
    public cd: ChangeDetectorRef
  ) {
    super(localEventBus);
  }

  ngOnInit() {
    this.eventSub = this.localEventBus
      .on('*')
      .pipe(filter(({ eventName }) => eventName !== 'onBlur'))
      .subscribe(({ eventName, data }) =>
        this.globalEventBus.send(eventName, data)
      );

    const listenOnBlur: Subscription = this.localEventBus
      .on('onBlur')
      .subscribe(e => {
        // ignore invalid fields when doing partial saves, to avoid weird validation errors
        const value = {};
        const controls = this.groupNode.controls;
        const ks = keys(controls);
        for (const k of ks) {
          const control = controls[k];
          if (control.valid) {
            value[k] = control.value;
          }
        }
        this.globalEventBus.send('onBlur', {
          name: this.model.key,
          value
        });
      });

    this.eventSub.add(listenOnBlur);
  }

  ngOnDestroy() {
    if (this.eventSub) {
      this.eventSub.unsubscribe();
    }
  }

  ngAfterViewInit() {
    // expose this part of the tree
    this.groupNode = this.group.controls[this.model.key] as UntypedFormGroup;
  }
}
