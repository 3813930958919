export enum CampaignStatus {
  IN_PROGRESS = 'IN_PROGRESS',
  VERIFIED = 'VERIFIED',
  FAILED = 'FAILED'
}

export interface CampaignDate {
  date: string;
  timezone: string;
  timezone_type: number;
}

export interface CampaignError {
  error_code: number;
  fields: string[];
  url: string;
  description: string;
}

export interface Campaign {
  sid: string;
  campaignStatus: CampaignStatus;
  messagingServiceSid: string;
  dateCreated: CampaignDate;
  dateUpdated: CampaignDate;
  errors: CampaignError[];
}
