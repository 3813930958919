import { EditorConfigType } from '../editor';
import {
  aligned,
  background,
  color,
  component,
  condition,
  listItem,
  overlay,
  padding,
  section,
  theme,
  visibleElements,
  wysywigChild
} from '../editor/dsl';

export const TextConfig = () =>
  component()
    .title('Text')
    .children({
      title: wysywigChild(EditorConfigType.SimpleText)
        .placeholder('Title')
        .description('This text represents title of the section.'),
      subtitle: wysywigChild(EditorConfigType.SimpleText)
        .placeholder('Subtitle')
        .description('This text represents subtitle of the section.'),
      text: wysywigChild(EditorConfigType.ExpandedText).placeholder('Text')
    })
    .sections(
      section('Design').forms(
        aligned().size('half'),
        color('separatorColor')
          .label('Separator Color')
          .size('half')
          .conditions('show', condition('elements.divider', '==', true)),
        theme(),
        padding(),
        background(),
        overlay().conditions(
          'show',
          condition('background.type', '===', 'image')
            .and()
            .condition('background.value', '!==', null)
        )
      ),
      section('Elements').forms(
        visibleElements().items(
          listItem('title', 'Headline'),
          listItem('subtitle', 'Subheadline'),
          listItem('divider', 'Separator'),
          listItem('text', 'Description')
        )
      )
    );

export function textConfigFactory() {
  return TextConfig().raw();
}
