<form [formGroup]="formGroup" #form class="form-root" (ngSubmit)="handleSubmit()">
  <div class="lum-df-layout-row lum-df-fields-wrapper">
    @for (model of formModel?.fields; track model.key) {
      @if (!model.isHidden && (model.conditionalVisible | async)) {
        <div [lumDfGrid]="model.size" [lumDfGridType]="model.type">
          <ng-container lumDynamicField [model]="model" [group]="formGroup" [options]="options" [data]="data">
          </ng-container>
        </div>
        @if (model.newLine) {
          <div style="flex: 100%"></div>
        }
      }
    }
  </div>
  <div class="lum-df-after-fields-wrapper">
    <ng-content></ng-content>
  </div>
</form>
