@if (!model.isHidden) {
  <div [formGroup]="group">
    <div [formGroupName]="model.key" class="group-control">
      @if (model.label && showTitle) {
        <h3 class="lum-df-group-title">{{ model.label }}</h3>
      }

      <div class="lum-df-layout-row">
        @for (subfieldModel of model.fields; track subfieldModel.key) {
          @if (subfieldModel.conditionalVisible | async) {
            <div
              [lumDfGrid]="subfieldModel.size"
              [lumDfGridType]="subfieldModel.type"
              [hidden]="subfieldModel.isHidden"
            >
              <ng-container
                lumDynamicField
                [model]="subfieldModel"
                [group]="(group?.controls)[model.key]"
                [options]="options"
              >
              </ng-container>
            </div>
            @if (subfieldModel.newLine) {
              <div style="flex: 100%"></div>
            }
          }
        }
      </div>
    </div>
  </div>
}
